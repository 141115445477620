import React from 'react';
import { Common } from '../../../@uno/api/common.service';
import { BaseEntity, EntityConstants, EntityProp } from '../../../@uno/api';
import { UC, UnoComponent, Images } from '../../../@uno/core';
import { BasePropEditor } from '../prop-base.comp';

import { EntityViewTypes } from '../../service/entity-prop.service';


export enum Status {
    NEW = 'new',
    EDITING = 'editing',
    EDIT_COMPLETE = 'edit_complete',
    EDIT_CANCELLED = 'edit_cancelled',
    SAVED = 'saved',
    REMOVING = 'removing',
    REMOVED = 'removed',
}

export class Item {
    status: Status = Status.NEW;
    value: any;
    editedValue: any;

    constructor(value: any = undefined, status: Status = Status.NEW) {
        this.value = value;
        this.editedValue = value;
        this.status = status;
    }
}

@UnoComponent({ id: 'PropMultipleEditor' })
export class PropMultipleEditor extends BasePropEditor {
    // vals: Array<any> = [];
    inputItems: Array<Item> = [];
    editing: boolean = false;
    editingIndex: number = -1;

    constructor(props: any) {
        super(props);

        let vals = Common.safeParse(this.getDefaultValue()) || [];
        if (!Array.isArray(vals)) {
            vals = [vals];
        }
        // this.profiler.log('Preparing multi-inputs...', vals);
        this.inputItems = vals.map(
            (v: any) => {
                return new Item(v, Status.SAVED);
            }
        );

        // this.state = {};
    }

    canProfile(): boolean {
        return false;
    }

    buildLabel(prop?: EntityProp) {
        return this.buildEmpty();
    }

    buildInput() {
        this.profiler.log('Build multi prop input: ', this.getEProp());
        return (
            <>
                {this.buildArrayViewActions()}
                {this.isEntityTypeProp() ? this.buildEntitiesView() : this.buildArrayView()}
                {/* this.buildPropEditDialog() */}
            </>
        );
    }

    buildPropEditDialog() {
        /*
        if (!this.editing || !this.isEntityTypeProp()) {
            return <></>;
        }
        */

        const dlg_id = Common.getUniqueKey('edit_add_prop_');

        const handleCloseDialog = () => {
            Common.hideDialog(undefined, dlg_id);
            this.editing = false;
            this.editingIndex = -1;
            // this.refreshState();
        };

        const item = this.inputItems[this.editingIndex];
        const eProp = this.getItemEProp()
        const otherProps: any = {
            ...this.getOtherProps(),
            canViewUUID: false,
            hideLabel: true,
            onPropChanged: (p: EntityProp, value: any) => {
                // this.profiler.log(`Added new prop: `, value);
                if (value) {
                    this.handleEditOnChange(p, value, this.editingIndex);
                }
                handleCloseDialog();
            },
        };

        const dlg = {
            id: dlg_id,
            title: `${item ? 'Edit' : 'Add'} : ${this.getLabel()}`,
            comp: (
                <UC.PropValue
                    entityProp={eProp}
                    defaultValue={item?.editedValue}
                    otherProps={otherProps}
                    editing={true}
                    setVal={true}
                />
            ),
            onClose: handleCloseDialog,
            styles: { border: '1px solid', padding: '5px', borderRadius: '5px', width: '600px', },
        };

        Common.showDialog(dlg);
        /*
        return (
            <UC.Dialog
                show={this.editing && this.isEntityTypeProp()}
                onClose={handleCloseDialog}
                title={`${item ? 'Edit' : 'Add'} : ${this.getLabel()}`}
                styles={{ border: '1px solid', padding: '5px', borderRadius: '5px', width: '600px', }}
            >
                <UC.PropValue
                    entityProp={eProp}
                    defaultValue={item?.editedValue}
                    otherProps={otherProps}
                    editing={true}
                    setVal={true}
                />
            </UC.Dialog>
        );
        */
    }

    buildEntitiesView() {
        if (this.isEntityTypeProp()) {
            this.profiler.log('Build Multiple Entities');
            const entityActions = this.getEntityActions();
            const listViewType = this.getExtraParams()?.listViewType;

            const otherProps: any = {
                listViewType: listViewType, //EntityConstants.ListViewTypes.Table.id,
                ...this.getOtherProps(),
                entityActions: entityActions,
            };

            if (this.isEntityInlineTypeProp()) {
                otherProps.viewType = EntityViewTypes.FULL;
                otherProps.canViewFull = false;
                otherProps.canEdit = false;
            } else {
                otherProps.viewType = EntityViewTypes.QUICK;
                otherProps.canViewFull = true;
                otherProps.canEdit = false;
                if (listViewType === undefined) {
                    otherProps.listViewType = EntityConstants.ListViewTypes.Chips.id;
                    otherProps.extras = { viewComp: 'EntityQuickView', ...otherProps.extras, };
                    // otherProps.noActions = true;
                }
            }

            const entities = this.inputItems.map(item => { return EntityConstants.build(item.editedValue); });

            const view = (
                <UC.MultiEntityView
                    appID={this.getAppID()}
                    categoryID={this.getEProp().category}
                    entities={entities}
                    key={Common.getUniqueKey()}
                    otherProps={otherProps}
                />
            );
            return view;
        }
    }


    buildArrayView() {
        this.profiler.log('Building Array View');
        const views = this.inputItems.map(
            (item: Item, index: number) => {
                const entity = item.editedValue;
                const entityActions = this.getEntityActions().map(
                    ea => {
                        const eaCopy = { ...Common.safeParse(ea) };
                        eaCopy.data = { entity: entity, index: index };
                        const action = eaCopy.action;
                        eaCopy.action = (obj: any) => {
                            if (action) {
                                return action(obj?.data?.entity, obj?.data?.index);
                            }
                        }
                        return eaCopy;
                    }
                );

                const itemEProp: EntityProp = this.getItemEProp();
                const editing = true;
                const otherProps: any = {
                    ...this.getOtherProps(),
                    hideLabel: true,
                    // viewType: this.getItemViewType(itemEProp),
                    onPropChanged: (p: EntityProp, value: any) => {
                        // this.profiler.log(`Changed: `, p, value, index);
                        this.handleEditOnChange(p, value, index)
                    },
                    primaryEntity: this.getEntity(),
                };

                return (
                    <UC.VSection cols='70%, 30%' key={Common.getUniqueKey()}>
                        {/*
                        <div>
                            <input
                                type='checkbox'
                                onChange={
                                    (evt: any) => {
                                        const selected = evt.target.selected;
                                        if(selected){

                                        }
                                    }
                                }
                            />
                        </div>
                        */}
                        <div>
                            <UC.PropValue
                                entityProp={itemEProp}
                                defaultValue={item.editedValue}
                                otherProps={otherProps}
                                editing={editing}
                            />
                        </div>
                        <div>
                            {this.buildActionBar(entityActions, true, true)}
                        </div>
                    </UC.VSection>
                )

            }
        );

        return views;
    }

    buildArrayViewActions() {
        const actions: Array<any> = [];
        const eProp = this.getEProp();
        const multiplicity = eProp ? Number(eProp.multiplicity).valueOf() : 1;
        const cannotAddMore = (multiplicity && multiplicity > 1 && multiplicity <= this.inputItems.length);
        if (!cannotAddMore) {
            actions.push({
                id: 'Add',
                action: () => {
                    this.profiler.log('Add Entity: ', this.isEntityTypeProp());
                    this.handleAddEdit();
                },
                icon: Images.Icon.plus
            });
        }

        if (this.inputItems.length > 0) {
            actions.push({
                id: 'Delete All', action: () => {
                    this.inputItems = [];
                    this.refreshState();
                }, icon: Images.Icon.clear
            });
        }

        /*
        const entityActions = this.getEntityActions();
        entityActions.forEach(eA => {
            actions.push(eA);
        });
        */

        return this.buildActionBar(actions, true, false);
    }

    getEntityActions() {
        return [
            { id: 'Edit', action: (entity: any, index: number) => { this.handleAddEdit(index); }, icon: Images.Icon.edit, hidden: (!this.isEntityTypeProp()), },
            { id: 'Move Up', action: (entity: any, index: number) => { this.handleMoveUp(index); }, icon: Images.Icon.moveUp },
            { id: 'Move Down', action: (entity: any, index: number) => { this.handleMoveDown(index); }, icon: Images.Icon.moveDown },
            { id: 'Delete', action: (entity: any, index: number) => { this.handleDeleteItem(index); }, icon: Images.Icon.delete },
        ];
    }

    getItemEProp(item?: Item): EntityProp {
        return Object.assign({}, this.getEProp(), { multiplicity: 1 });
    }

    getDefaultValue() {
        let defValue = this.props.defaultValue;
        if (!defValue) {
            const entity = this.getEntity();
            // this.profiler.log('defvalue entity : ', entity);
            if (entity) {
                defValue = EntityConstants.getValue(this.getEProp(), entity);
            }
            // this.profiler.log('Multiple defvalue from entity: ', defValue);
        }
        return defValue ? defValue : [];
    }

    handleEditOnChange = (p: EntityProp = this.getEProp(), value?: any, index: number = -1) => {
        this.profiler.log('Changed..., ', value, p, index);
        if (index < 0) {
            index = this.inputItems.length;
        }

        const item = (index < this.inputItems.length) ? this.inputItems[index] : new Item();

        const pDataType = p.dataType;
        switch (pDataType) {
            case EntityConstants.PropType.ENTITY:
                value = value ? EntityConstants.buildTruncatedEntity(value) : undefined;
                break;
            default:
            // this.profiler.log('Changed Multi-Input Value : ', this.inputEntity);
        }

        item.editedValue = value;
        this.inputItems[index] = item;

        // set it (again).
        this.handleSaveItem(item, index);
    }

    handleAddEdit = (index: number = -1) => {
        this.editingIndex = index;
        const item = this.inputItems[index];

        if (this.isEntityTypeProp()) {
            /*
            this.editing = true;
            this.refreshState(false);
            */
            this.buildPropEditDialog();
        } else if (!item || index < 0) {
            this.handleEditOnChange();
        }
    }

    handleDeleteItem(ind: number) {
        this.inputItems.splice(ind, 1);
        this.refreshState();
    }

    handleMoveUp(ind: number) {
        // this.profiler.log(`Move up: `, ind + 1);
        const list = this.inputItems;
        if (ind > 0) {
            const temp = { ...list[ind - 1] };
            list[ind - 1] = list[ind];
            list[ind] = temp;
            this.refreshState();
        }
    }

    handleMoveDown(ind: number) {
        // this.profiler.log(`Move down: `, ind + 1);
        const list = this.inputItems;
        if (ind < (this.inputItems.length - 1)) {
            const temp = { ...list[ind + 1] };
            list[ind + 1] = list[ind];
            list[ind] = temp;
            this.refreshState();
        }
    }

    handleSaveItem(item: Item, index: number) {
        item.value = item.editedValue;
        item.status = Status.SAVED;
        this.refreshState();
    }

    handleSaveAll() {
        if (!this.getPropChangedHandler()) {
            return;
        }

        const savedVals: any = [];
        let unsavedEdits = false;
        this.inputItems.forEach(item => {
            if (item.status === Status.SAVED) {
                savedVals.push(item.value);
            } else {
                unsavedEdits = true;
            }
        });

        if (unsavedEdits) {
            alert(`A few UNSAVED changes found. Please review them.`);
        } else {
            this.getPropChangedHandler()(this.getEProp(), savedVals);
            // this.profiler.log('Saved. Refresh... ', savedVals);
            Common.showMessage(`Saved: ${savedVals.length} ${this.getEProp()?.label}`, true);
        }
    }

    refreshState(saveItems: boolean = true) {
        if (saveItems) { this.handleSaveAll(); }
        this.reRender();
    }

}
