

type EventObject = { name: any, listeners: any, trail: Array<any> }

class EventManagerImpl {
    LISTENERS_MAP: any = {};

    constructor() {
        console.log('Event Manager Service Initialized.');
    }

    getEventObject(eventName: any) {
        let eventObj: EventObject = this.LISTENERS_MAP[eventName];
        if (!eventObj) {
            eventObj = { name: eventName, listeners: {}, trail: [] };
            this.LISTENERS_MAP[eventName] = eventObj;
        }

        return eventObj;
    }

    getMoniteredEvents(): Array<any> {
        return Object.keys(this.LISTENERS_MAP)
    }

    getEventListners(eventName: any) {
        return this.getEventObject(eventName).listeners;
    }

    getEventTrail(eventName: any) {
        return this.getEventObject(eventName).trail;
    }

    register(eventName: any, listener: any): string {
        const listenerID = eventName + '_listener_' + Math.round(Math.random() * 999999);
        this.getEventListners(eventName)[listenerID] = listener;
        // console.log(`Registered Listener ${listener.name} for event '${eventName}' with ID - ${listenerID}`);
        return listenerID;
    }

    unregister(listenerID: any, callback = (id: any) => { }) {
        // console.log(`UnRegistering Listener with ID - ${listenerID}`);
        this.getMoniteredEvents().forEach(
            eventName => {
                const listeners = this.getEventListners(eventName);
                if (listeners[listenerID]) {
                    delete listeners[listenerID];
                    // console.log('Unregistered event listener - ', listenerID);
                    callback(listenerID);
                }
            }
        );
    }

    emit(eventName: any, eventData: any = undefined) {
        // console.log('Event emitted - ', eventName);
        // eventData = { ...eventData, eventName };
        this.getEventTrail(eventName).unshift({ data: eventData, time: new Date().getTime() });
        const listeners = this.getEventListners(eventName);
        Object.keys(listeners).forEach(id => {
            // const data = { ...eventData, listener: id };
            listeners[id](eventData);
        });
    }

}

const EM = new EventManagerImpl();

export { EM };

