import { UnoModule } from '../@uno/core';
import OpenViduModule from './OpenVidu/openvidu.module';
import AframeModule from './aframe/aframe.module';
import ChartJsModule from './chart.js/chart.js.module';
import D3Module from './d3/d3.module';
import DicomViewerModule from './dicom/dicom.module';
import OCRModule from './ocr/ocr.module';
import { SvgDesignerComp } from './svg-designer/svg-designer.comp';
import TableauModule from './tableau/tableau.module';
// The Module...

@UnoModule(
    {
        id: 'Uno3rdPartyModule',
        imports: [
            TableauModule,
            D3Module,
            ChartJsModule,
            OpenViduModule,
            OCRModule,
            DicomViewerModule,
            AframeModule,
        ],
        components: [
            SvgDesignerComp,
        ],
    }
)
export default class Uno3rdPartyModule {

}

